



















import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCookiesBar from '@/components/SygniCookiesBar.vue';
@Component({
  components: { SygniCookiesBar }
})
export default class AuthModule extends Vue {

  get backgroundClass(): string {
    switch(this.$route.name){
      case('login'):{
        return "background-one";
      }
      default:{
        return "background-two";
      }
    }
  }
}
