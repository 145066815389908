var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-module",
    class: _vm.backgroundClass
  }, [_c('div', {
    staticClass: "background"
  }), _c('div', {
    staticClass: "logo"
  }), _c('router-view'), _vm._m(0), _c('div', {
    staticClass: "footer-text"
  }, [_vm._v(" Fundequate Sarl-S provides accounting, corporate services and reporting platform for Venture Capital and Private Equity funds based on Alternative Investment Fund structure. ")]), _c('sygni-cookies-bar')], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "https://fundequate.com/"
    }
  }, [_c('div', {
    staticClass: "home-page-text hoverable-a-element"
  }, [_vm._v(" Go to home page ")])]);
}]

export { render, staticRenderFns }